import { EventType, UsageEventName, UserEvent } from "@employee-experience/common/lib/Models/UsageTelemetry";

interface IReportScreenUsageEvents{
    root:string;
    generateCustomVrReport:UserEvent;
    generateFullVrReport:UserEvent;
    generateCustomCarpoolReport:UserEvent;
    generateFullCarpoolReport:UserEvent;
}

export const ReportsScreenUsageEvents: IReportScreenUsageEvents = {
  root: 'Spark.Registration.ReportsScreen',
  generateCustomVrReport: {
    subFeature: 'Spark.Registration.ReportsScreen.GenerateCustomVrReport',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  generateFullVrReport: {
    subFeature: 'Spark.Registration.ReportsScreen.GenerateFullVrReport',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  generateCustomCarpoolReport: {
    subFeature: 'Spark.Registration.ReportsScreen.GenerateCustomCarpoolReport',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  generateFullCarpoolReport: {
    subFeature: 'Spark.Registration.ReportsScreen.GenerateFullCarpoolReport',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
};