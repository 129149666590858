import {IStyle, mergeStyleSets, Depths, mergeStyles} from '@fluentui/react';
import {SParkTheme} from '../../theme';

export const navigationPanelStyles = mergeStyleSets({
  root: {
    background: SParkTheme.palette?.white,
    minHeight: '5vh',
    alignContent: 'center',
  } as IStyle,

  userDetailsStyles: {
    fontSize: '16px',
    fontWeight: '500',
    opacity: 0.9,
  } as IStyle,

  divStylesLink: {
    overflow: 'hidden',
    flexFlow: 'wrap',
    paddingBottom: 2,
    textUnderlineStyle: 'nounderline',
  } as IStyle,

  divStylesRedirect: {
    overflow: 'hidden',
    flexFlow: 'wrap',
    textUnderlineStyle: 'nounderline',
  } as IStyle,
  stackItemStyles: {
    background: SParkTheme.palette?.white,
    padding: 2,
    flexFlow: 'row wrap',
    display: 'flex',
    margin: 2,
    justifyContent: 'space-between',
    boxShadow: Depths.depth16,
  } as IStyle,

  mainHeadingStyles: {
    padding: '30px 0px 25px 5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'Space-between',
  } as IStyle,
  mainHeadingFontStyles: {
    fontFamily: 'Segoe UI Light',
    fontSize: 30,
    fontWeight: 'bold',
  } as IStyle,

  linkMyVehicleStyles: {
    padding: '5px 10px',
    margin: 2,
    textAlign: 'center',
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: 19,
    color: SParkTheme.palette?.blue,
  } as IStyle,

  linkStylesOut: {
    padding: '5px 20px',
    margin: 2,
    marginTop: 5,
    cursor: 'pointer',
    textAlign: 'center',
    textUnderlineStyle: 'nounderline',
    display: 'inline-block',
    fontSize: 15,
    selectors: {
      '&:active': {
        color: SParkTheme.palette?.blueMid,
      },
      '&:hover': {
        color: SParkTheme.palette?.blue,
      },
    },
  } as IStyle,
});

export const linkRouteStyles = {
  padding: '5px 30px 5px 15px',
  margin: 2,
  textAlign: 'center',
  display: 'inline-block',
  fontWeight: 'bold',
  fontSize: 19,
  selectors: {
    '&:hover': {
      color: SParkTheme.palette?.blue,
    },
  },
};

export const userVehicleLinkRouteStyles = (pathValue: string) =>
  mergeStyles({
    ...linkRouteStyles,
    color: pathValue === '/' ? SParkTheme.palette?.blueMid : SParkTheme.palette?.blue,
    textDecoration: pathValue === '/' ? 'underline' : 'none',
  });

export const adminLinkRouteStyles = (pathValue: string) =>
  mergeStyles({
    ...linkRouteStyles,
    color: pathValue === '/admin' ? SParkTheme.palette?.blueMid : SParkTheme.palette?.blue,
    textDecoration: pathValue === '/admin' ? 'underline' : 'none',
  });

export const reportLinkRouteStyles = (pathValue: string) =>
  mergeStyles({
    ...linkRouteStyles,
    color: pathValue === '/reports' ? SParkTheme.palette?.blueMid : SParkTheme.palette?.blue,
    textDecoration: pathValue === '/reports' ? 'underline' : 'none',
  });
