import {mergeStyles} from '@fluentui/react';

export const reportsPanelStyle = mergeStyles({
  justifyContent: 'flex-start',
  margin: '2% 1% 2% 1%',
});
export const customReportBarStyle = mergeStyles({
  width: '40%',
});
export const reportButtonContainerStyles = mergeStyles({
  width: '60%',
  margin: '22px 0 0 0',
  justifyContent: 'space-between',
});
