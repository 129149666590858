import {Route, BrowserRouter, Routes, Navigate} from 'react-router-dom';
import {MainPanelBody, NavigationPanel, AdminScreen, ReportsScreen} from '../pages';
import {IAppRouteProps} from './AppRoute.types';

const AppRoute = ({isAdminEnabled, areReportsEnabled}: IAppRouteProps): JSX.Element => {
  return (
    <>
      <BrowserRouter>
        <NavigationPanel isAdminEnabled={isAdminEnabled} areReportsEnabled={areReportsEnabled} />
        <Routes>
          <Route path="/" element={<MainPanelBody />} />
          {isAdminEnabled && <Route path="/admin" element={<AdminScreen />} />}
          {areReportsEnabled && <Route path="/reports" element={<ReportsScreen />} />}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoute;
